import { get } from 'lodash';
import i18n from 'i18n';
import moment from 'moment';
import { LOYALTY_PROGRAM_ALIAS, LOYALTY_REWARD, REWARDS_PROGRAM_ALIAS } from 'appConstants';
import { pagePaths } from 'components/rewards/RewardsPageHeader';
import { AppState } from '../rootReducer';
import { PagesList } from '../settings/layout';
import {
  LoyaltyTotalResponse,
  RewardsTabStatus,
  LoyaltyMonthlySummaryResponse,
  LoyaltyRewardsResponse,
  PromoPendingStatus,
  FetchActiveCoreProgramResponse
} from './types';
import { isFeatureOrComponentEnabled, Programs } from '../settings';
import { isDanubeCompany, isAppHubBroomField } from '../emailMarketing/settings';

const getMerchantProgramsArray = (state: AppState) => Object.entries(get(state, 'settings.merchantPrograms', [0, []]));
const getMSKForRewards = (state: AppState) =>
  get(
    getMerchantProgramsArray(state).find(([key, value]: any) =>
      value.find((item: any) =>
        state.settings?.programs
          ?.filter(({ alias }: Programs) => [LOYALTY_PROGRAM_ALIAS, REWARDS_PROGRAM_ALIAS].includes(alias))
          .map(({ programId }: Programs) => programId)
          ?.includes(item)
      )
    ),
    '0',
    ''
  );

export const getMIDForRewards = (state: AppState) => {
  const merchantSequenceKey = getMSKForRewards(state);
  if (merchantSequenceKey) {
    const currentLocation = get(state, 'settings.locations').find(
      (location: any) => location.merchant_sequence_key === merchantSequenceKey
    );
    return get(currentLocation, 'merchant_number', '');
  }
  return '';
};

export const getBusinessIdForRewards = (state: AppState) => {
  const merchantSequenceKey = getMSKForRewards(state);
  if (merchantSequenceKey) {
    const currentLocation = get(state, 'settings.locations').find(
      (location: any) => location.merchant_sequence_key === merchantSequenceKey
    );
    return get(currentLocation, 'business_id', '');
  }
  return '';
};

export const getPromos = (state: AppState) => get(state, 'rewards.promos', {});
export const getIsFirstTimeLoaded = (state: AppState): boolean => get(state, 'rewards.isFirstTimeLoaded', {});
export const getPromosSettings = (state: AppState) => get(state, ['rewards', 'promosSettings'], {});

export const getPromo = (state: AppState, promoKey: string) => get(getPromos(state), promoKey, {});
export const getCoreProgramEnabledFlag = (state: AppState): boolean =>
  get(getPromos(state), [LOYALTY_REWARD, 'enabled'], false);
export const getCoreProgramId = (state: AppState): string => get(getPromos(state), [LOYALTY_REWARD, 'id'], '');
export const getCoreProgramStartDate = (state: AppState): string =>
  get(getPromos(state), [LOYALTY_REWARD, 'loyaltyStartDate'], '');
export const isCoreProgramEnabled = (state: AppState): boolean =>
  Boolean(getCoreProgramId(state)) && getCoreProgramEnabledFlag(state);

export const isRewardsPromosLoading = (state: AppState) => get(state, 'rewards.isPromosLoading', false);
export const isRewardsPromosRequested = (state: AppState) => get(state, 'rewards.isPromosRequested', false);
export const isPromosLoading = (state: AppState) => get(state, 'rewards.loading', false);
export const isPromoLoading = (state: AppState, promoKey: string) =>
  get(getPromosSettings(state), [promoKey, 'loading'], false);

export const isAnyPromoLoading = (state: AppState): boolean =>
  Object.values(state.rewards.promosSettings).some(data => data.loading);

export const getCrmClientId = (state: AppState) => get(state, 'rewards.crmClientId', 0);
export const getGiftChainId = (state: AppState) => get(state, 'rewards.giftChainId', 0);
export const getCrmCampaignStatuses = (state: AppState) => get(state, 'rewards.emCampaignStatuses', {});

export const getPromoSettings = (state: AppState, promoKey: string) =>
  get(getPromosSettings(state), [promoKey, 'settings'], {});

export const getPromoCancellationDate = (state: AppState, promoKey: string) =>
  get(getPromosSettings(state), [promoKey, 'cancellationDate'], null);

export const getCoreConfirmModal = (state: AppState) => get(state, 'rewards.isCoreConfirmModalOpen', false);
export const getCoreSuccessModal = (state: AppState) => get(state, 'rewards.isCoreSuccessModalOpen', false);
export const getCoreFreezeModal = (state: AppState) =>
  get(state, 'rewards.isFreezeModalWithFutureActivationDateOpen', false);

export const getLoyaltyCalculatorSideBar = (state: AppState) => get(state, 'rewards.isLoyaltyCalculatorOpen', false);

export const getRewardsAutomationSuccessModal = (state: AppState) =>
  get(state, 'rewards.isRewardsAutomationSuccessModalOpen', false);
export const getRewardsAutomationAreYouSureModal = (state: AppState) =>
  get(state, 'rewards.isRewardsAutomationAreYouSureModalOpen', false);
export const getRewardsAutomationResetModal = (state: AppState) =>
  get(state, 'rewards.isRewardsAutomationResetModalOpen', false);

export const getPromosError = (state: AppState) => get(state, 'rewards.error', null);

export const getRewardsTabStatus = (state: AppState, pages: PagesList, pageName?: string): RewardsTabStatus[] => {
  const rewardsPathName = !!pageName && pageName === 'loyalty_root' ? 'loyalty' : 'rewards';
  return [
    {
      path: pagePaths[rewardsPathName].coreProgram(pages),
      notAvailable: getCrmClientId(state) === 0,
      hint: i18n.t(
        'LoyaltyCoreTabHint',
        'You must save your company profile settings before going to rewards core program'
      )
    },
    {
      path: pagePaths[rewardsPathName].automation(pages),
      notAvailable: !isCoreProgramEnabled(state) || moment(getCoreProgramStartDate(state)).isAfter(moment()),
      hint: i18n.t(
        'LoyaltyAutomationTabHint',
        'Your core loyalty program should be active before going to loyalty automation.'
      )
    },
    {
      path: pagePaths[rewardsPathName].members(pages),
      notAvailable: !isCoreProgramEnabled(state) || moment(getCoreProgramStartDate(state)).isAfter(moment()),
      hint: i18n.t(
        'LoyaltyMembersTabHint',
        'Your core loyalty program should be active before going to loyalty members.'
      )
    }
  ];
};

export const isRewardsEnabled = (state: AppState) => isFeatureOrComponentEnabled(state, 'rewards_root');

export const isDanubeCompanyWithLoyaltyEnabled = (state: AppState) =>
  (isDanubeCompany(state) && isRewardsEnabled(state)) || false;

export const getShouldUsePublicSitesLink = (state: AppState) =>
  ((isDanubeCompany(state) || isAppHubBroomField(state)) && isRewardsEnabled(state)) || false;

export const getLoyaltyMonthlySummary = (state: AppState): LoyaltyMonthlySummaryResponse =>
  get(state, 'rewards.loyaltyMonthlySummary', {});
export const getLoyaltyRewards = (state: AppState): LoyaltyRewardsResponse => get(state, 'rewards.loyaltyRewards', {});
export const getLoyaltyTotal = (state: AppState): LoyaltyTotalResponse => get(state, 'rewards.loyaltyTotal', {});

export const getLastVersionCoreProgram = (state: AppState): PromoPendingStatus =>
  getPromo(state, 'lastVersionCoreProgram');

export const getCoreProgram = (state: AppState): FetchActiveCoreProgramResponse | null =>
  state?.rewards?.coreProgram ?? null;

import React from 'react';
import styled from 'styled-components';
import { separateLastWord } from 'services/utils';
import { textTitle } from 'layout/mixins';
import { Hint, HintProps } from 'shared-components/Hint';
import { PagesList } from 'store/settings/layout';
import { getPagePath } from 'services/navigation';
import { deviceMedia } from 'appConstants';

export const pagePaths = {
  emailMarketing: {
    automation: (pages: PagesList) => getPagePath(pages, 'emailMarketing_root', 'emailMarketing_automation'),
    automationEditor: (pages: PagesList) =>
      getPagePath(pages, 'emailMarketing_root', 'emailMarketing_automation_editor'),
    profile: (pages: PagesList) => getPagePath(pages, 'emailMarketing_root', 'emailMarketing_profile')
  },
  rewards: {
    overview: (pages: PagesList) => getPagePath(pages, 'rewards_root', 'rewards_overview'),
    coreProgram: (pages: PagesList) => getPagePath(pages, 'rewards_root', 'rewards_core-program'),
    automation: (pages: PagesList) => getPagePath(pages, 'rewards_root', 'rewards_automation'),
    members: (pages: PagesList) => getPagePath(pages, 'rewards_root', 'rewards_members')
  },
  loyalty: {
    overview: (pages: PagesList) => getPagePath(pages, 'loyalty_root', 'loyalty_overview'),
    coreProgram: (pages: PagesList) => getPagePath(pages, 'loyalty_root', 'loyalty_core_program'),
    automation: (pages: PagesList) => getPagePath(pages, 'loyalty_root', 'loyalty_automation'),
    members: (pages: PagesList) => getPagePath(pages, 'loyalty_root', 'loyalty_members')
  }
};

export interface RewardsPageHeaderProps {
  className?: string;
  hint?: string;
  title: string;
  children?: string | React.ReactComponentElement<any>;
  hintPlacement?: HintProps['placement'];
  dataTestId?: string;
}

const PageHeaderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const PageHeader = styled.h1`
  ${textTitle};
  margin: 0;
  text-transform: uppercase;
`;

const PageAction = styled.div`
  text-align: right;
`;

const NoWrapItem = styled.span`
  white-space: nowrap;
`;

export const RewardsPageHeaderComponent = ({
  className,
  title,
  children,
  hint,
  hintPlacement = 'bottom',
  dataTestId
}: RewardsPageHeaderProps) => {
  const [titleWithoutLastWord, titleLastWord] = separateLastWord(title);

  return (
    <div className={className}>
      <PageHeaderContainer data-test-id={dataTestId && `${dataTestId}-header`}>
        <PageHeader>
          <span>{titleWithoutLastWord}</span>{' '}
          <NoWrapItem>
            {titleLastWord}
            {hint ? <Hint text={hint} placement={hintPlacement} isNowrap /> : null}
          </NoWrapItem>
        </PageHeader>
      </PageHeaderContainer>
      <PageAction>{children}</PageAction>
    </div>
  );
};

export const RewardsPageHeader = styled(RewardsPageHeaderComponent)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.baseColors.grey};
  padding: 20px 15px;
  @media ${deviceMedia.mobile} {
    margin: 0 15px;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 10px;
  }
`;

import { ActionType, createReducer } from 'typesafe-actions';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { rewardsActions } from './actions';
import { RewardsState } from './types';
import {
  setDefaultPromoSettings,
  failRequestPromoSettings,
  receivePromos,
  receivePromoSettings,
  requestPromoSettings,
  discardChangesPromoSettings
} from './modifiers';

const today = moment();
const SINCE_LAST_VISIT_RANGE = {
  start: 30,
  end: 135,
  step: 15
};
export const rewardsInitialState = {
  isFirstTimeLoaded: false,
  loading: false,
  crmClientId: 0,
  emCampaignStatuses: {
    loyalty_reward: false,
    welcome: false,
    almost_reward: false,
    miss_you: false,
    anniversary: false,
    birthday: false
  },
  giftChainId: null,
  isCoreConfirmModalOpen: false,
  isCoreSuccessModalOpen: false,
  isFreezeModalWithFutureActivationDateOpen: false,
  isRewardsAutomationSuccessModalOpen: { status: false, campaign: '' },
  isRewardsAutomationAreYouSureModalOpen: false,
  isRewardsAutomationResetModalOpen: false,
  isLoyaltyCalculatorOpen: false,
  isPromosLoading: false,
  isPromosRequested: false,
  error: null,
  promos: {
    loyalty_reward: { id: '', enabled: false, crmCampaign: false },
    welcome: { id: '', enabled: false, crmCampaign: false },
    almost_reward: { id: '', enabled: false, crmCampaign: false },
    miss_you: { id: '', enabled: false, crmCampaign: false },
    anniversary: { id: '', enabled: false, crmCampaign: false },
    birthday: { id: '', enabled: false, crmCampaign: false }
  },
  promosSettings: {
    loyalty_reward: {
      error: null,
      loading: false,
      settings: {
        loyaltyStartDate: today,
        emailMessaging: false,
        receiptMessaging: false,
        receiptText: '',
        rewardAmount: '',
        pointThreshhold: ''
      }
    },
    welcome: {
      error: null,
      loading: false,
      settings: {
        loyaltyStartDate: today,
        includeIncentive: false,
        pointQuantity: 10,
        emailMessaging: false,
        receiptMessaging: false,
        receiptText: ''
      }
    },
    almost_reward: {
      error: null,
      loading: false,
      settings: {
        loyaltyStartDate: today,
        pointsFromReward: 10,
        emailMessaging: false,
        receiptMessaging: false,
        receiptText: ''
      }
    },
    miss_you: {
      error: null,
      loading: false,
      settings: {
        loyaltyStartDate: today,
        daysSinceLastVisit: SINCE_LAST_VISIT_RANGE.start,
        emailMessaging: false
      }
    },
    anniversary: {
      error: null,
      loading: false,
      settings: {
        loyaltyStartDate: today,
        includeIncentive: false,
        pointQuantity: 10,
        emailMessaging: false
      }
    },
    birthday: {
      error: null,
      loading: false,
      settings: {
        loyaltyStartDate: today,
        includeIncentive: false,
        pointQuantity: 10,
        emailMessaging: false
      }
    }
  },

  loyaltyMonthlySummary: {
    visits: {
      amount: 0,
      MoM: 0,
      YoR: 0
    },
    sales: {
      amount: 0,
      MoM: 0,
      YoR: 0
    },
    avgTicket: {
      amount: 0,
      MoM: 0,
      YoR: 0
    }
  },
  loyaltyRewards: {
    total: {
      last30Days: {
        amount: 0,
        rewardsValue: 0
      },
      last12Months: {
        amount: 0,
        rewardsValue: 0
      }
    },
    chartData: {
      last30Days: [],
      last12Months: []
    }
  },
  loyaltyTotal: {
    members: {
      amount: 0
    },
    rewards: {
      amount: 0,
      rewardsValue: 0
    }
  }
};

export const rewardsReducer = createReducer<RewardsState, ActionType<typeof rewardsActions>>(rewardsInitialState)
  .handleAction(rewardsActions.promos.request, state => ({
    ...state,
    loading: true,
    isPromosLoading: true,
    isPromosRequested: true,
    error: null
  }))
  .handleAction(rewardsActions.promos.success, (state, action) => receivePromos(state, action))
  .handleAction(rewardsActions.promos.failure, (state, action) => ({
    ...state,
    loading: false,
    isPromosLoading: false,
    error: action.payload
  }))
  .handleAction(rewardsActions.savePromos.request, state => ({ ...state, loading: true, error: null }))
  .handleAction(rewardsActions.savePromos.success, state => ({ ...state, loading: false, error: null }))
  .handleAction(rewardsActions.savePromos.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }))
  .handleAction(rewardsActions.crmData.request, state => ({ ...state, loading: true, error: null }))
  .handleAction(rewardsActions.crmData.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    crmClientId: action.payload.id,
    emCampaignStatuses: action.payload.statuses
  }))
  .handleAction(rewardsActions.crmData.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }))
  .handleAction(rewardsActions.promoSettings.request, (state, action) => requestPromoSettings(state, action))
  .handleAction(rewardsActions.promoSettings.success, (state, action) => receivePromoSettings(state, action))
  .handleAction(rewardsActions.promoSettings.failure, (state, action) => failRequestPromoSettings(state, action))
  .handleAction(rewardsActions.promoSettingsSetDefault, (state, action) => setDefaultPromoSettings(state, action))
  .handleAction(rewardsActions.promoSettingsDiscardChanges, state => discardChangesPromoSettings(state))
  .handleAction(rewardsActions.toggleCoreConfirmModal, (state, action) => ({
    ...state,
    isCoreConfirmModalOpen: action.payload
  }))
  .handleAction(rewardsActions.toggleCoreSuccessModal, (state, action) => ({
    ...state,
    isCoreSuccessModalOpen: action.payload
  }))
  .handleAction(rewardsActions.toggleFreezeModal, (state, action) => ({
    ...state,
    isFreezeModalWithFutureActivationDateOpen: action.payload
  }))
  .handleAction(rewardsActions.toggleLoyaltyCalculator, (state, action) => ({
    ...state,
    isLoyaltyCalculatorOpen: action.payload
  }))
  .handleAction(rewardsActions.toggleRewardsAutomationSuccessModal, (state, action) => ({
    ...state,
    isRewardsAutomationSuccessModalOpen: action.payload
  }))
  .handleAction(rewardsActions.toggleRewardsAutomationAreYouSureModal, (state, action) => ({
    ...state,
    isRewardsAutomationAreYouSureModalOpen: action.payload
  }))
  .handleAction(rewardsActions.toggleRewardsAutomationResetModal, (state, action) => ({
    ...state,
    isRewardsAutomationResetModalOpen: action.payload
  }))

  .handleAction(rewardsActions.loyaltyMonthlySummary.request, state => ({ ...state, loading: true, error: null }))
  .handleAction(rewardsActions.loyaltyMonthlySummary.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    loyaltyMonthlySummary: action.payload
  }))
  .handleAction(rewardsActions.loyaltyMonthlySummary.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
    loyaltyMonthlySummary: cloneDeep(rewardsInitialState.loyaltyMonthlySummary)
  }))

  .handleAction(rewardsActions.loyaltyRewards.request, state => ({ ...state, loading: true, error: null }))
  .handleAction(rewardsActions.loyaltyRewards.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    loyaltyRewards: action.payload
  }))
  .handleAction(rewardsActions.loyaltyRewards.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
    loyaltyRewards: cloneDeep(rewardsInitialState.loyaltyRewards)
  }))

  .handleAction(rewardsActions.loyaltyTotal.request, state => ({ ...state, loading: true, error: null }))
  .handleAction(rewardsActions.loyaltyTotal.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    loyaltyTotal: action.payload
  }))
  .handleAction(rewardsActions.loyaltyTotal.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
    loyaltyTotal: cloneDeep(rewardsInitialState.loyaltyTotal)
  }))
  .handleAction(rewardsActions.fetchActiveCoreProgram.success, (state, { payload }) => ({
    ...state,
    coreProgram: payload
  }))
  .handleAction(rewardsActions.updateIsFirstTimeLoaded, (state, { payload }) => ({
    ...state,
    isFirstTimeLoaded: payload
  }));

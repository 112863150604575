import styled from 'styled-components';
import { getColor } from '../layout/theme';

export const TextInput = styled.input<{ isInvalid?: boolean }>`
  color: ${props => getColor(props, 'darkGrey')};
  height: 34px;
  width: 100%;
  padding: 8px;
  border: 1px solid ${props => getColor(props, props.isInvalid ? 'red' : 'grey')};
  outline: none;
  appearance: none;
  border-radius: 0;
`;
TextInput.displayName = 'TextInput';
